<template>
  <div class="payment auth">
    <nav class="container">
      <div class="d-flex justify-content-between align-items-center mb-4 pt-4">
        <img src="@/assets/logo.png" />
        <div>
          <button class="btn btn-outline-light" @click="$user.logout">
            Sair
          </button>
        </div>
      </div>
    </nav>

    <div class="container">
      <div class="row">
        <div class="col-md-7 col-lg-5 mx-auto">
          <div class="card">
            <div class="card-body d-flex justify-content-center">
              <loading-spinner />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted () {
    this.$store
      .dispatch('user/checkSubscription')
      .then(active => {
        if (active) {
          this.$router.replace({ name: 'home' })
        } else {
          this.$router.replace({ name: 'plans' })
        }
      })
  }
}
</script>
